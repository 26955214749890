import React from "react";
import styles from "./ViewUser.module.css";
import moment from "moment";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";

import AddRoundedIcon from "@material-ui/icons/AddRounded";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";

import TableComp from "../../utils/Table/Table";
import AppLoader from "../../utils/AppLoader/AppLoader";

import { connect } from "react-redux";
import { getAllUsers, getAllVendors } from "../../../containers/user/actions";
import { withRouter } from "react-router-dom";

const ViewUser = (props) => {
  const [user, setUser] = React.useState(props.user);
  const [vendor, setVendor] = React.useState(props.vendor);
  const [showEntries, setShowEntries] = React.useState(10);
  const [showUsers, setShowUsers] = React.useState("users");
  const [searchVal, setSearchVal] = React.useState("");

  React.useEffect(() => {
    if (!props.user) {
      props.getAllUsers();
    }
    setUser(props.user);
  }, [props.user]);

  React.useEffect(() => {
    if (!props.vendor) {
      props.getAllVendors();
    }
    setVendor(props.vendor);
  }, [props.vendor]);

  let isLoading = !user && !vendor;
  let showData = !isLoading;
  let rowData = [];
  

  if (showUsers == "users") {
    !isLoading &&
      user &&
      user.forEach((user1, index) => {
        if (searchVal !== "") {
          if (
            user1.name.toLowerCase().includes(searchVal.toLowerCase()) ||
            user1._id.toLowerCase().includes(searchVal.toLowerCase()) ||
            user1.phoneNumber.toLowerCase().includes(searchVal.toLowerCase())
          ) {
            rowData.push([
              index + 1,
              user1.name,
              user1.phoneNumber,
              moment(user1.dob).format("DD/MM/YYYY"),
              user1.wallet,
              user1.active ? (
                <CheckCircleOutlineRoundedIcon style={{ color: "green" }} />
              ) : (
                <HighlightOffRoundedIcon style={{ color: "red" }} />
              ),

              <React.Fragment>
                <Tooltip title="View Transactions">
                  <IconButton
                    onClick={() =>
                      props.history.push(
                        "/admin/user/VIEW-TRANSACTIONS?userId=" + user1._id
                      )
                    }
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              </React.Fragment>,
            ]);
          }
        } else if (index + 1 <= showEntries || showEntries == "All") {
          rowData.push([
            index + 1,
            user1.name,
            user1.phoneNumber,
            moment(user1.dob).format("DD/MM/YYYY"),
            user1.wallet,
            user1.active ? (
              <CheckCircleOutlineRoundedIcon style={{ color: "green" }} />
            ) : (
              <HighlightOffRoundedIcon style={{ color: "red" }} />
            ),

            <React.Fragment>
              <Tooltip title="View Transactions">
                <IconButton
                  onClick={() =>
                    props.history.push(
                      "/admin/user/VIEW-TRANSACTIONS?userId=" + user1._id
                    )
                  }
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            </React.Fragment>,
          ]);
        }
      });
  } else if (showUsers == "vendors") {
    !isLoading &&
      vendor &&
      vendor.forEach((vendor, index) => {
     
        if (searchVal !== "") {
          if (
            vendor.name.toLowerCase().includes(searchVal.toLowerCase()) ||
            vendor._id.toLowerCase().includes(searchVal.toLowerCase()) ||
            vendor.phoneNumber.toLowerCase().includes(searchVal.toLowerCase())
          ) {
            rowData.push([
              index + 1,
              vendor.name,
              vendor.phoneNumber,
              moment(vendor.dob).format("DD/MM/YYYY"),
              vendor.wallet,
              vendor.active ? (
                <CheckCircleOutlineRoundedIcon style={{ color: "green" }} />
              ) : (
                <HighlightOffRoundedIcon style={{ color: "red" }} />
              ),

              <React.Fragment>
                <Tooltip title="View Transactions">
                  <IconButton
                    onClick={() =>
                      props.history.push(
                        "/admin/user/VIEW-TRANSACTIONS?userId=" + user._id
                      )
                    }
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              </React.Fragment>,
            ]);
          }
        }

        if (index + 1 <= showEntries || showEntries == "All") {
          rowData.push([
            index + 1,
            vendor.name,
            vendor.phoneNumber,
            moment(vendor.dob).format("DD/MM/YYYY"),
            vendor.wallet,
            vendor.active ? (
              <CheckCircleOutlineRoundedIcon style={{ color: "green" }} />
            ) : (
              <HighlightOffRoundedIcon style={{ color: "red" }} />
            ),

            <React.Fragment>
              <Tooltip title="View Transactions">
                <IconButton
                  onClick={() =>
                    props.history.push(
                      "/admin/user/VIEW-TRANSACTIONS?userId=" + user._id
                    )
                  }
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            </React.Fragment>,
          ]);
        }
      });
  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.leftHeader}>
          <p>Show Entries</p>
          <Select
            value={showEntries}
            onChange={(e) => setShowEntries(e.target.value)}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={30}>50</MenuItem>
            <MenuItem value={"All"}>All</MenuItem>
          </Select>
          <p>Show User Type</p>
          <Select
            value={showUsers}
            onChange={(e) => setShowUsers(e.target.value)}
          >
            <MenuItem value={"users"}>users</MenuItem>
            <MenuItem value={"vendors"}>vendors</MenuItem>
          </Select>
        </div>

        <div className={styles.rightHeader}>
          <TextField
            label="Search Here"
            className={styles.search}
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
          />
        </div>
      </div>

      {isLoading && <AppLoader />}

      {showData && (
        <TableComp
          columns={[
            "Sl No",
            "Name",
            "Phone Number",
            "Date Of Birth",
            "Wallet Balance",
            "Active",
            "Action",
          ]}
          rows={rowData}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.user.user,
  vendor: state.user.vendor,
});
export default withRouter(
  connect(mapStateToProps, { getAllUsers, getAllVendors })(ViewUser)
);
