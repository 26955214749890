import React, { useState } from "react";
import styles from "./MapComp.module.css";
import Paper from "@material-ui/core/Paper";
import { defaultCenter, mapApiKey } from "../../config/config";
import GoogleMapReact from "google-map-react";
import { connect } from "react-redux";
import { getAllWarehouse } from "../../containers/warehouse/actions";
import { getAllcoordinates } from "../../containers/map/actions";
import StoreMallDirectoryRoundedIcon from "@material-ui/icons/StoreMallDirectoryRounded";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Box } from "@material-ui/core";
import firebase from "firebase";
import axios from "axios";
import moment from "moment";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  },
}));

const Marker = (props) => {
  return (
    <StoreMallDirectoryRoundedIcon style={{ color: "red", fontSize: 40 }} />
  );
};

const Marker1 = (props) => {
  return (
    <React.Fragment>
      {/* <Box
        display="flex"
        flexDirection="column"
        gap="10px"
        alignItems="center"
        textAlign="center"
      >
        <img
          src={props.profilePic}
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
          alt="delivery-boy-profile-pic"
        />
        <Box display="flex" flexDirection="column" alignItems="left" gap="5px">
          <Typography variant="p" color="inherit" fontWeight="500">
            {props?.name}
          </Typography>
          <Typography variant="p" color="inherit" fontWeight="500">
            {" "}
            {props?.phoneNumber}
          </Typography>
        </Box>
      </Box> */}

      <DirectionsBikeIcon
        style={{ color: "blue", fontSize: 20, cursor: "pointer" }}
        onClick={props.onClick}
      />
    </React.Fragment>
  );
};

const MapComp = (props) => {
  const [deliveryBoyCord, setDeliveryBoyCord] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleMarkerClick = async (id) => {
    const data = await getDeliveryBoy(id);
    console.log(data);
    setSelectedMarker(data);
    if (data) {
      setShowPopup(true);
    }
  };

  React.useEffect(() => {
    if (!props.warehouse) {
      props.getAllWarehouse();
    }
  }, [props.warehouse]);

  React.useEffect(() => {
    props.getAllcoordinates();

    const interval = setInterval(() => {
      if (!props.coordinates) {
        props.getAllcoordinates();
      }
      console.log("This will run 10 every second!");
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const getDeliveryBoy = async (id) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `/dashboard/delivery/getDeliveryBoy/${id}`
      );
      console.log(data);
      setLoading(false);
      return data?.user;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    firebase
      .database()
      .ref(props.userId)
      .once("value")
      .then((snapshot) => {
        console.log(snapshot.val().location);
        let arr = Object.keys(snapshot.val()?.location).map(
          (val) => snapshot.val().location[val]
        );

        let result = [];
        for (const key in snapshot.val()?.location) {
          const obj = snapshot.val().location[key];
          const newObj = { ...obj, id: key };
          result.push(newObj);
        }
        console.log(arr);
        console.log(result);
        setDeliveryBoyCord(result);
      });

    const interval = setInterval(() => {
      firebase
        .database()
        .ref(props.userId)
        .once("value")
        .then((snapshot) => {
          console.log(snapshot.val().location);
          let arr = Object.keys(snapshot.val()?.location).map(
            (val) => snapshot.val().location[val]
          );

          let result = [];
          for (const key in snapshot.val()?.location) {
            const obj = snapshot.val().location[key];
            const newObj = { ...obj, id: key };
            result.push(newObj);
          }
          console.log(arr);
          console.log(result);
          setDeliveryBoyCord(result);
        });
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.container}>
      <Paper variant="outlined" className={styles.paper}>
        <div className={styles.headers}>
          <div className={styles.label}>
            <p>Warehouse</p>
            <StoreMallDirectoryRoundedIcon
              style={{ color: "red", fontSize: 30 }}
            />
            <p>Delivery Boy</p>
            <DirectionsBikeIcon style={{ color: "blue", fontSize: 30 }} />
          </div>
        </div>

        <div className={styles.mapContent}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyA7gzKZCXeOD2SindEnG0MwxdsgQgoCjEU",
            }}
            defaultCenter={defaultCenter}
            defaultZoom={12}
          >
            {props.warehouse &&
              props.warehouse.map((val) => {
                return (
                  <Marker
                    marker={val}
                    lat={val.coordinates[0]}
                    lng={val.coordinates[1]}
                  />
                );
              })}
            {deliveryBoyCord.length > 0 &&
              deliveryBoyCord.map((val) => {
                console.log(val);
                return (
                  <Marker1
                    key={val.id}
                    marker={val}
                    lat={val.latitude}
                    lng={val.longitude}
                    name={"delivery"}
                    phoneNumber={"96672345"}
                    profilePic={"sfsdf"}
                    type={"delivery"}
                    onClick={() => handleMarkerClick(val.id)}
                    style={{ cursor: "pointer" }}
                  />
                );
              })}
          </GoogleMapReact>
          {showPopup && selectedMarker && (
            <div
              className={styles.popup}
              onMouseLeave={() => setShowPopup(false)}
            >
              <img src={selectedMarker.selfieUrl} />
              <h6>{selectedMarker.name}</h6>
              <p>{selectedMarker.phoneNumber}</p>
              <p>
                Check In Time :{" "}
                {moment(new Date(selectedMarker.checkInTime)).format("hh:mm a")}{" "}
              </p>
            </div>
          )}
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  warehouse: state.warehouse.warehouse,
  coordinates: state.map.coordinates,
});
export default connect(mapStateToProps, { getAllWarehouse, getAllcoordinates })(
  MapComp
);
