import React  from 'react'

import styles from './WarehouseDetails.module.css';

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button' 
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'

import EditRoundedIcon from '@material-ui/icons/EditRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

import TableComp from '../../utils/Table/Table';
import AppLoader from '../../utils/AppLoader/AppLoader';

import {connect} from 'react-redux'
import {getProduct} from '../../../containers/warehouse/actions'
import {withRouter,useLocation} from 'react-router-dom'
import ConfirmAlert from '../../utils/ConfirmAlert/ConfirmAlert';


const WarehouseProducts = (props) => { 
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    
    const [products,setProducts] = React.useState(props.warehouseProducts);
    const [showEntries,setShowEntries] = React.useState(10)
    const [searchVal,setSearchVal] = React.useState("");

    
    React.useEffect(() => {
            if(query.get("warehouseId")) {     
                props.getProduct(query.get("warehouseId"));  
            } else {
                props.history.push("/admin/warehouse/VIEW-WAREHOUSE")
            }
        
    },[]);

    React.useEffect(() => {
        setProducts(props.warehouseProducts)
    },[props.warehouseProducts])

    let isLoading = !products;
    let showData = !isLoading;
    let rowData = [];

    !isLoading && products.forEach((products,index) => {
        if(index+1 <= showEntries || showEntries == "All") {
            if(products.productName.toLowerCase().includes(searchVal.toLowerCase())){
                rowData.push([
                    index + 1,
                    products.categoryId.categoryName,
                    products.productName,
                    products.stockLeft,
                    products.productPrice,
                    products.active
                    ?
                <CheckCircleOutlineRoundedIcon style={{color: "green"}} />
                    :
                <HighlightOffRoundedIcon style={{color: "red"}}/>,
                        
                ])
            }
        }
    });
 
 
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.leftHeader}>
                    <p>Show Entries</p>
                    <Select value={showEntries} onChange={e => setShowEntries(e.target.value)}>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={30}>50</MenuItem>
                        <MenuItem value={"All"}>All</MenuItem>
                    </Select>
                </div>
                <div className={styles.rightHeader}>
                    <TextField
                        label="Search Here"
                        className={styles.search}
                        value={searchVal}
                        onChange={e => setSearchVal(e.target.value)}
                    />
                </div>
            </div>

            {isLoading && <AppLoader />}

            {showData &&
            <TableComp 
                columns={["Sl No","Category","Product Name","Stock","Price","Active"]}
                rows={rowData}
            />}

        </div>
    )
}
const mapStateToProps = state => ({
    warehouseProducts: state.warehouse.warehouseProducts
})
export default withRouter(connect(mapStateToProps,{getProduct})(WarehouseProducts));