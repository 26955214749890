import React from 'react'
import styles from './AddCoupon.module.css'

import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField' 
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button' 
import Switch from '@material-ui/core/Switch' 
import CircularProgress from '@material-ui/core/CircularProgress'

import AddRoundedIcon from '@material-ui/icons/AddRounded';

import {connect} from 'react-redux'
import {getAllCoupon} from '../../../containers/coupon/actions' 
import {showAlert} from '../../../containers/app/actions'
import axios from 'axios'
import {withRouter} from 'react-router-dom'
import { KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; 
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
const AddCoupon = (props) => { 
    const [formData,setFormData] = React.useState({
        code: "",
        amount: "",
        expireDate: "",
        isActive: true,
        couponId: ""
    });

    const [error,setError] = React.useState({
        code: false,
        amount: false,
        expireDate: false
    });

    const [loading,setLoading] = React.useState(false);

    const validate = () => {
        const err = {code: false,amount: false,expireDate: false};
        let validData = true;
        setError({...err});
        Object.keys(err).forEach(key => {
            if(formData[key] == "") {
                err[key] = `${key} field cannot be empty`
                validData = false;
            } 
        }) 
        setError({...err});
        return validData;
    }

    const onSubmit = () => {
        if(validate()) {
            setLoading(true);

            axios({
                method: "post",
                url: "/admin/coupon/addCoupon",
                data: {
                    ...formData
                }
            }).then(res => {
                setLoading(false);
                props.showAlert("Coupon Added Succesfully");
                props.getAllCoupon()
                props.history.push("/admin/coupon/VIEW-COUPON")
            }).catch(err => {
                setLoading(false);
                if(err && err.response && err.response.data && err.response.data.error) {
                    props.showAlert(err.response.data.error)
                } else {
                    console.log(err);   
                    props.showAlert("Something went wrong ! Try Again")
                }
            })
        }
    }
    let minDate = new Date().toISOString();
    return (
        <div className={styles.container}>
            <Paper variant="outlined" className={styles.paper}>
                <h1>Add Coupon</h1>

                <div className={styles.row}>
                    <TextField 
                        label="Coupon Code"
                        className={styles.catName}
                        value={formData.code}
                        onChange={e => setFormData({...formData,code: e.target.value})}
                        onBlur={(e) => setFormData({...formData,code: e.target.value.trim()})}
                        error={error.code}
                        helperText={error.code}
                    />
                    <TextField 
                        label="Coupon Amount"
                        type='number'
                        className={styles.catName}
                        value={formData.amount}
                        onChange={e => setFormData({...formData,amount: e.target.value})}
                        onBlur={(e) => setFormData({...formData,amount: e.target.value.trim()})}
                        error={error.amount}
                        helperText={error.amount}
                    />

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            className={styles.halfWidth}
                            label="Expiry Date"
                            error={error.expireDate}
                            helperText={error.expireDate}
                            format="dd/MM/yyyy"
                            value={formData.expireDate}
                            disablePast
                            InputAdornmentProps={{ position: "start" }}
                            onChange={date => setFormData({ ...formData, expireDate: date })}
                        />
                    </MuiPickersUtilsProvider>
                    {/* <TextField 
                        label=""
                        type="date"
                        disablePast
                        InputLabelProps={{ shrink: true, required: true }}
                        className={styles.halfWidth}
                        value={formData.expireDate}
                        onChange={e => setFormData({...formData,expireDate: e.target.value})}
                        error={error.expireDate}
                        helperText={error.expireDate}
                        min={minDate}
                        inputProps={{
                            min: minDate
                        }}
                    /> */}

                </div>

                <div className={styles.row}>
 
                    <FormControlLabel
                        className={styles.switch}
                        control={<Switch checked={formData.isActive} onChange={(e) => setFormData({...formData,isActive: !formData.isActive})}  color="primary" />}
                        label="Coupon Active"
                    /> 
                </div>

                <div className={styles.row}>
                    {loading
                        ?
                    <Button color="primary" variant="contained" startIcon={<CircularProgress color="inherit" size={20} />}>Loading ...</Button>
                        :
                    <Button color="primary" variant="contained" startIcon={<AddRoundedIcon />} onClick={onSubmit}>Add Coupon</Button>}
                </div>
            </Paper>
        </div>
    )
}

export default withRouter(connect(null,{getAllCoupon,showAlert})(AddCoupon));