// export const backendUrl = "https://yopaan-api.applore.in/api"
// export const backendUrl = "http://localhost:9000/api";
export const backendUrl = "https://api.yopaan.com/api";

//  export const  firebaseConfig = {
//     apiKey: "AIzaSyAiGxPYOUrXinGDjSu4QjOi5X5Z9uVro5I",
//     authDomain: "fireapp-7cada.firebaseapp.com",
//     databaseURL: "https://fireapp-7cada.firebaseio.com",
//     projectId: "fireapp-7cada",
//     storageBucket: "fireapp-7cada.appspot.com",
//     messagingSenderId: "405101290869",
//     appId: "1:405101290869:web:ef1db981033039c46b9f3c"
// }

// orignal config

export const firebaseConfig = {
  apiKey: "AIzaSyAaYgGdwavpj9_WxA_POoFrLeNrpuM_pGo",
  authDomain: "yopaan-d81b3.firebaseapp.com",
  databaseURL:
    "https://yopaan-d81b3-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "yopaan-d81b3",
  storageBucket: "yopaan-d81b3.appspot.com",
  messagingSenderId: "1021002864603",
  appId: "1:1021002864603:web:3d28832844500c220179fe",
};

export const defaultCenter = {
  lat: 28.5355,
  lng: 77.391,
};
export const mapApiKey = "AIzaSyC1Cz13aBYAbBYJL0oABZ8KZnd7imiWwA4";
