import React from 'react';
import styles from './User.module.css';

import BreadCrump from '../../components/BreadCrump/BreadCrump'; 
import TopBar from '../../components/TopBar/TopBar';

import ViewUser from '../../components/User/ViewUser/ViewUser';
import Transactions from '../../components/User/TransactionsDetails/Transactions';


const User = (props) => {
    const [state,setState] = React.useState("VIEW-USER");   

    React.useEffect(() => {
        if(Object.keys(navData).includes(props.match.params.type)) {
            setState(props.match.params.type)
        }
    },[props.match.params.type]);

    const navData = {
        "VIEW-USER": {
            name: "View Users/Vendors",
            path: "/admin/user/VIEW-USER"
        },
        "VIEW-TRANSACTIONS": {
            name: "View Transactions",
            path: "/admin/user/VIEW-TRANSACTIONS"
        },
    }
    return (
        <div className={styles.container}>
            <TopBar head="Users/Vendors" />
            <BreadCrump 
                navItems={[{name:"Users/Vendors",path: "/admin/user/VIEW-USER"},navData[state]]}
            />

            {state == "VIEW-USER" && <ViewUser />}
            {state == "VIEW-TRANSACTIONS" && <Transactions />}


        </div>
    )
}

export default User;
