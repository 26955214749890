import * as actionTypes from './actionTypes'
import axios from 'axios'
 
export const getAllUsers = () => dispatch => {
    dispatch({
        type: actionTypes.SET_USER,
        payload: false
    })

    axios({
        method: "get",
        url: "/admin/user/getAllUsers"
    }).then(res => {
        dispatch({
            type: actionTypes.SET_USER,
            payload: res.data.users
        })
    }).catch(err => {
        dispatch({
            type: actionTypes.SET_USER,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}


export const getAllVendors = () => dispatch => {
    dispatch({
        type: actionTypes.SET_VENDOR,
        payload: false
    })

    axios({
        method: "get",
        url: "/admin/user/getAllVendors"
    }).then(res => {
        dispatch({
            type: actionTypes.SET_VENDOR,
            payload: res.data.users
        })
    }).catch(err => {
        dispatch({
            type: actionTypes.SET_VENDOR,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}

export const getThreeUsers = () => dispatch => {
    dispatch({
        type: actionTypes.SET_USER_ALL,
        payload: false
    })

    axios({
        method: "get",
        url: "/admin/user/getThreeUsers"
    }).then(res => {
        dispatch({
            type: actionTypes.SET_USER_ALL,
            payload: res.data.users
        })
    }).catch(err => {
        dispatch({
            type: actionTypes.SET_USER_ALL,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}

export const getAllTransaction = (userId) => dispatch => {
    axios({
        method: "get",
        url: "/admin/user/getAllTransaction?userId=" + userId,
        
    }).then(res => {
        dispatch({
            type: actionTypes.SET_TRANSACTION,
            payload: res.data.Transactions
        })
    }).catch(err => {  
        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}