import * as actionTypes from './actionTypes'

const initialState = { 
  user: false, 
  vendor: false, 
  transaction : false,
} 
const reducer =  (state = initialState, action) => {
    switch (action.type) { 
        case actionTypes.SET_USER:
            return {...state,user: action.payload}
        case actionTypes.SET_VENDOR:
            return {...state,vendor: action.payload}
        case actionTypes.SET_TRANSACTION:
            return {...state,transaction: action.payload}
        case actionTypes.SET_USER_ALL:
            return {...state,usersAll: action.payload}
        default:
            return state
    }
}

export default reducer;