import React from 'react'
import styles from './AddCategory.module.css'

import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField' 
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button' 
import Switch from '@material-ui/core/Switch' 
import CircularProgress from '@material-ui/core/CircularProgress'

import AddRoundedIcon from '@material-ui/icons/AddRounded';

import {connect, useSelector} from 'react-redux'
import {getAllCategory} from '../../../containers/category/actions' 
import {showAlert} from '../../../containers/app/actions'
import axios from 'axios'
import {withRouter} from 'react-router-dom'
import { useEffect } from 'react'



const AddCategory = (props) => { 
   useEffect(()=>{
    props.getAllCategory()
   },[])
   const categoryData= useSelector((store)=>store.category)

  
   

    const [formData,setFormData] = React.useState({
        categoryName: "",
        description: "",
        active: true
    });

    const [error,setError] = React.useState({
        categoryName: false,
        description: false
    });

    const [loading,setLoading] = React.useState(false);

   
    const isCategoryTaken = (name, description) => {
      
      return categoryData.category.some((category) => 
            category.categoryName === name || category.description === description
        );
    };


    const validate = () => {
        const err = {categoryName: false,description: false};
        let validData = true;
        setError({...err});
        Object.keys(err).forEach(key => {
            if(formData[key] == "") {
                err[key] = `${key} field cannot be empty`
                validData = false;
            } 
        }) 
        setError({...err});
        return validData;
    }

    const onSubmit = () => {
        if(isCategoryTaken(formData.categoryName,formData.description)){
            props.showAlert("Category Already Exists");
        //     setFormData({ 
        //     categoryName: "",
        //     description: ""
        // },)
        }
        else if(validate() && !isCategoryTaken(formData.categoryName,formData.description)) {
            setLoading(true);

            axios({
                method: "post",
                url: "/dashboard/category/addCategory",
                data: {
                    ...formData
                }
            }).then(res => {
                setLoading(false);
                props.showAlert("Category Added Succesfully");
                props.getAllCategory()
                props.history.push("/admin/category/VIEW-CATEGORY")
            }).catch(err => {
                setLoading(false);
                if(err && err.response && err.response.data && err.response.data.error) {
                    props.showAlert(err.response.data.error)
                } else {
                    console.log(err);   
                    props.showAlert("Something went wrong ! Try Again")
                }
            })
        }
    }
    return (
        <div className={styles.container}>
            <Paper variant="outlined" className={styles.paper}>
                <h1>Add Category</h1>

                <div className={styles.row}>
                    <TextField 
                        label="Category Name"
                        className={styles.catName}
                        value={formData.categoryName}
                        onChange={e => setFormData({...formData,categoryName: e.target.value})}
                        onBlur={(e) => setFormData({...formData,categoryName: e.target.value.trim()})}
                        error={error.categoryName}
                        helperText={error.categoryName}
                    />
                    <TextField 
                        label="Category Description"
                        className={styles.catName}
                        value={formData.description}
                        onChange={e => setFormData({...formData,description: e.target.value})}
                        onBlur={(e) => setFormData({...formData,description: e.target.value.trim()})}
                        error={error.description}
                        helperText={error.description}
                    />
 
                    <FormControlLabel
                        className={styles.switch}
                        control={<Switch checked={formData.active} onChange={(e) => setFormData({...formData,active: !formData.active})}  color="primary" />}
                        label="Category Active"
                    /> 
                </div>

                <div className={styles.row}>
                    {loading
                        ?
                    <Button color="primary" variant="contained" startIcon={<CircularProgress color="inherit" size={20} />}>Loading ...</Button>
                        :
                    <Button color="primary" variant="contained" startIcon={<AddRoundedIcon />} onClick={onSubmit}>Add Category</Button>}
                </div>
            </Paper>
        </div>
    )
}

export default withRouter(connect(null,{getAllCategory,showAlert})(AddCategory));