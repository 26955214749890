import React from 'react'
import styles from './ChatComp.module.css'
import firebase from 'firebase'

import ChatSystem from './ChatSystem/ChatSystem'

import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import {connect} from 'react-redux'
import {getThreeUsers} from '../../containers/user/actions'

const ChatComp = (props) => {    
    const [chats,setChats] = React.useState([]);
    const [users,setUsers] = React.useState({});
    const [activeUserId,setActiveUserId] = React.useState(false);
    const [searchVal,setSearchVal] = React.useState("");
    const [showUsers,setShowUsers] = React.useState("USER")


    React.useEffect(() => {
        firebase.database().ref("/").once('value').then((snapshot) => {
            if(snapshot.val()){
                let chatList = Object.keys(snapshot.val())
                setChats(chatList)
                if(chatList.length > 0) {
                    setActiveUserId(chatList[0])
                }
            }
        })

    },[]);




    React.useEffect(() => {


        if(props.userData) { 
            let arr = {};
            props.userData.forEach(val => {
                arr[val._id] = val
            }) 
            setUsers(arr)
        } else {
            props.getThreeUsers();
        }
    },[props.userData]);

    return (
        <Paper className={styles.container} variant="outlined"> 
            <div className={styles.leftContent}>
                <h1>Replies</h1>
                    <div className={styles.selectUser}>
                        <p>Show User Type</p>
                        <Select className={styles.selectUserSelect} value={showUsers} onChange={e => setShowUsers(e.target.value)}>
                            <MenuItem value={"USER"}>Users</MenuItem>
                            <MenuItem value={"STORE"}>Vendors</MenuItem>
                            <MenuItem value={"DELIVERY-BOY"}>Delivery Boy</MenuItem>

                        </Select>
                    </div>
                <div className={styles.searchBar}>
                    <input type="text" placeholder="Search Here.." value={searchVal} onChange={e => setSearchVal(e.target.value)} />
                </div>
                {chats.map(userId => {
                    if(users[userId] && users[userId].userType == showUsers){
                        if(users[userId] &&  users[userId].name.toLowerCase().includes(searchVal.toLowerCase())) {
                            return (
                                <div 
                                    onClick={() => setActiveUserId(userId)}
                                    className={activeUserId == userId ? styles.activeListItem : styles.listItem}
                                >{users[userId] && users[userId].name}
                                </div>
                            )
                        } else return;
                    }
                })}
            </div>
            <div className={styles.rightContent}> 
                {chats.map(userId => {
                    return (
                        <ChatSystem userId={userId} name={users[userId] && users[userId].name} activeUserId={activeUserId} />
                    )
                })} 
            </div> 
        </Paper>
    )
}

const mapStateToProps = state => ({
    userData: state.user.usersAll
})
export default connect(mapStateToProps,{getThreeUsers})(ChatComp);