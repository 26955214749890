import React from 'react';
import BreadCrump from '../../components/BreadCrump/BreadCrump'; 
import AddCoupon from '../../components/Coupon/AddCoupon/AddCoupon';
import EditCoupon from '../../components/Coupon/EditCoupon/EditCoupon';
import ViewCoupon from '../../components/Coupon/ViewCoupon/ViewCoupon';
import TopBar from '../../components/TopBar/TopBar';
import styles from './Coupon.module.css';


const Coupon = (props) => {
    const [state,setState] = React.useState("VIEW-COUPON");   

    React.useEffect(() => {
        if(Object.keys(navData).includes(props.match.params.type)) {
            setState(props.match.params.type)
        }
    },[props.match.params.type]);

    const navData = {
        "VIEW-COUPON": {
            name: "View Coupon",
            path: "/admin/coupon/VIEW-COUPON"
        },
        "ADD-COUPON": {
            name: "Add Coupon",
            path: "/admin/coupon/ADD-COUPON"
        },
        "EDIT-COUPON": {
            name: "Edit Coupon",
            path: "/admin/coupon/EDIT-COUPON"
        },
    }
    return (
        <div className={styles.container}>
            <TopBar head="Coupon" />
            <BreadCrump 
                navItems={[{name:"Coupon",path: "/admin/coupon/VIEW-COUPON"},navData[state]]}
            />

            {state == "VIEW-COUPON" && <ViewCoupon />}
            {state == "ADD-COUPON" && <AddCoupon />}
            {state == "EDIT-COUPON" && <EditCoupon />}
        </div>
    )
}

export default Coupon;