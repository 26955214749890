import React from 'react'
import styles from './EditCoupon.module.css'

import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField' 
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button' 
import Switch from '@material-ui/core/Switch' 
import CircularProgress from '@material-ui/core/CircularProgress'

import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';

import {connect} from 'react-redux'
import {getAllCoupon} from '../../../containers/coupon/actions' 
import {showAlert} from '../../../containers/app/actions'
import axios from 'axios'
import {withRouter,useLocation} from 'react-router-dom'


const EditCoupon = (props) => { 
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [formData,setFormData] = React.useState({
        code: "",
        amount: "",
        expireDate: "",
        isActive: true,
        couponId: ""
    });


    const [error,setError] = React.useState({
        code: false,
        amount: false,
        expireDate: false
    });

    const [loading,setLoading] = React.useState(false);

    React.useEffect(() => {
        if(props.coupon) {
            if(query.get("couponId")) {
                props.coupon.forEach(coup => {
                    if(coup._id == query.get("couponId")) { 
                        setFormData({...formData,...coup,couponId: coup._id})
                    }
                })
            } else {
                props.showAlert("Data Not Found")
                props.history.push("/admin/coupon/VIEW-COUPON")
            }
        } else {
            props.getAllCoupon();
        }
    },[props.coupon]);

    const validate = () => {
        const err = {code: false,amount: false,expireDate: false};
        let validData = true;
        setError({...err});
        Object.keys(err).forEach(key => {
            if(formData[key] == "") {
                err[key] = `${key} field cannot be empty`
                validData = false;
            } 
        }) 
        setError({...err});
        return validData;
    }

    const onSubmit = () => {
        if(validate()) {
            setLoading(true);

            axios({
                method: "put",
                url: "/admin/coupon/editCoupon?couponId="+formData.couponId,
                data: {
                    ...formData
                }
            }).then(res => {
                setLoading(false);
                props.showAlert("Coupon Updated Succesfully");
                props.getAllCoupon()
                props.history.push("/admin/coupon/VIEW-COUPON")
            }).catch(err => {
                setLoading(false);
                if(err && err.response && err.response.data && err.response.data.error) {
                    props.showAlert(err.response.data.error)
                } else {
                    console.log(err);   
                    props.showAlert("Something went wrong ! Try Again")
                }
            })
        }
    }
    return (
        <div className={styles.container}>
            <Paper variant="outlined" className={styles.paper}>
                <h1>Edit Coupon</h1>

                <div className={styles.row}>
                    <TextField 
                        label="Coupon Code"
                        className={styles.catName}
                        value={formData.code}
                        onChange={e => setFormData({...formData,code: e.target.value})}
                        onBlur={(e) => setFormData({...formData,code: e.target.value.trim()})}
                        error={error.code}
                        helperText={error.code}
                    />
                    <TextField 
                        label="Coupon Amount"
                        className={styles.catName}
                        value={formData.amount}
                        onChange={e => setFormData({...formData,amount: e.target.value})}
                        onBlur={(e) => setFormData({...formData,amount: e.target.value.trim()})}
                        error={error.amount}
                        helperText={error.amount}
                    />
                    <TextField 
                        label="Expiry Date"
                        type="date"
                        InputLabelProps={{ shrink: true, required: true }}
                        className={styles.halfWidth}
                        value={formData.expireDate}
                        onChange={e => setFormData({...formData,expireDate: e.target.value})}
                        error={error.expireDate}
                        helperText={error.expireDate}
                    />

                
                </div>

                <div className={styles.row}>

                   
 
                    <FormControlLabel
                        className={styles.switch}
                        control={<Switch checked={formData.isActive} onChange={(e) => setFormData({...formData,isActive: !formData.isActive})}  color="primary" />}
                        label="Coupon Active"
                    /> 
                </div>

                <div className={styles.row}>
                    {loading
                        ?
                    <Button color="primary" variant="contained" startIcon={<CircularProgress color="inherit" size={20} />}>Loading ...</Button>
                        :
                    <Button color="primary" variant="contained" startIcon={<UpdateRoundedIcon />} onClick={onSubmit}>Update Coupon</Button>}
                </div>
            </Paper>
        </div>
    )
}

const mapStateToProps = state => ({
    coupon: state.coupon.coupon
})
export default withRouter(connect(mapStateToProps,{getAllCoupon,showAlert})(EditCoupon));