import { combineReducers } from 'redux';  
import appReducer from './app/reducer'; 
import productReducer from './product/reducer'
import categoryReducer from './category/reducer'
import chatReducer from './chat/reducer'
import couponReducer from './coupon/reducer'
import warehouseReducer from './warehouse/reducer'
import managerReducer from './manager/reducer'
import mapReducer from './map/reducer'
import deliveryReducer from './delivery/reducer'
import userReducer from './user/reducer'
import ordersReducer from './orders/reducer'
import homeReducer from './home/reducer'

export default combineReducers({ 
  app: appReducer,
  product: productReducer,
  category: categoryReducer,
  chat: chatReducer,
  coupon: couponReducer,
  warehouse: warehouseReducer,
  manager: managerReducer,
  map: mapReducer,
  delivery: deliveryReducer,
  user: userReducer,
  orders: ordersReducer,
  home: homeReducer
});
