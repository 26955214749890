import * as actionTypes from './actionTypes'
import axios from 'axios'
 
export const getAllCoupon = () => dispatch => {
    dispatch({
        type: actionTypes.SET_COUPON,
        payload: false
    })

    axios({
        method: "get",
        url: "/admin/coupon/getAllCoupon"
    }).then(res => {
        dispatch({
            type: actionTypes.SET_COUPON,
            payload: res.data.coupon
        })
    }).catch(err => {
        dispatch({
            type: actionTypes.SET_COUPON,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}

export const onCouponDelete = (couponId) => dispatch => {
    axios({
        method: "delete",
        url: "/admin/coupon/deleteCoupon",
        data: {
            couponId
        }
    }).then(res => {
        dispatch(getAllCoupon())
        dispatch({
            type: "SHOW_ALERT",
            payload: "Coupon Deleted Successfully"
        })
    }).catch(err => {  
        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}
