import React  from 'react'

import styles from './TransactionDetails.module.css';
import moment from 'moment';

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button' 
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'

import EditRoundedIcon from '@material-ui/icons/EditRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

import TableComp from '../../utils/Table/Table';
import AppLoader from '../../utils/AppLoader/AppLoader';

import {connect} from 'react-redux'
import {getAllTransaction} from '../../../containers/user/actions'
import {withRouter,useLocation} from 'react-router-dom'


const Transactions = (props) => { 
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    
    const [transactions,setTransactions] = React.useState(props.transaction);
    const [showEntries,setShowEntries] = React.useState(10)

    
    React.useEffect(() => {
            if(query.get("userId")) {     
                props.getAllTransaction(query.get("userId"));  
            } else {
                props.history.push("/admin/user/VIEW-USER")
            }
        
    },[]);

    React.useEffect(() => {
        setTransactions(props.transaction)
    },[props.transaction])

    let isLoading = !transactions;
    let showData = !isLoading;
    let rowData = [];

    !isLoading && transactions && transactions.forEach((transactions,index) => {
        if(index+1 <= showEntries || showEntries == "All") {
                rowData.push([
                    index + 1,
                    transactions.paymentId,
                    transactions.orderId ? transactions.orderId : "null",
                    transactions.type,
                    transactions.amount,
                    moment(transactions.createdAt).format('DD/MM/YYYY hh:mm '),
                    transactions.refunded
                            ?
                        <CheckCircleOutlineRoundedIcon style={{color: "green"}} />
                            :
                        <HighlightOffRoundedIcon style={{color: "red"}}/>,


                ])
            
        }
    });
 
 
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.leftHeader}>
                    <p>Show Entries</p>
                    <Select value={showEntries} onChange={e => setShowEntries(e.target.value)}>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={30}>50</MenuItem>
                        <MenuItem value={"All"}>All</MenuItem>
                    </Select>
                </div>
            </div>

            {isLoading && <AppLoader />}

            {showData &&
            <TableComp 
                columns={["Sl No","Payment Id","Order Id","Type","Amount","Transaction Date","Refunded"]}
                rows={rowData}
            />}

        </div>
    )
}
const mapStateToProps = state => ({
    transaction: state.user.transaction
})
export default withRouter(connect(mapStateToProps,{getAllTransaction})(Transactions));